<template>
    <div v-if="showFullPageLoader" class="spinner-wrapper-fullpage d-flex">
        <span class="spinner-border spinner-border mx-auto mt-4" role="status"></span>
    </div>
    <div v-if="hasPages" class="catalog-single-page">
        <div class="tab-container px-260">
            <ul class="nav nav-tabs nav-bordered nav-justified w-30" role="tablist" ref="navTabs">
                <li class="nav-item">
                    <a @click="goToPagesTab"
                        href="#pages-tab"
                        data-bs-toggle="tab"
                        data-opens="pagesTab"
                        aria-expanded="false"
                        class="nav-link fw-bold text-uppercase text-dark active"
                    >
                        {{ "catalog.tab.pages".trans() }}
                    </a>
                </li>
                <li class="nav-item">
                    <a @click="goToOverviewTab"
                        href="#overview-tab"
                        data-bs-toggle="tab"
                        data-opens="overviewTab"
                        aria-expanded="true"
                        class="nav-link fw-bold text-uppercase text-dark"
                    >
                        {{ "catalog.tab.overview".trans() }}
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content mt-4">
            <div v-if="showOverviewData"
                class="d-flex px-260 mb-4"
            >
                <div class="me-5">
                    <p class="mb-0 text-uppercase fs-6 text-muted fw-bold">{{ 'overview.products.total'.trans() }}</p>
                    <p class="text-dark fs-4">{{ overviewData.totalCatalogProducts }}</p>
                </div>
                <div class="me-5">
                    <p class="mb-0 text-uppercase fs-6 text-muted fw-bold">{{ 'overview.cards.total'.trans() }}</p>
                    <p class="text-dark fs-4">{{ overviewData.totalCatalogCards }}</p>
                </div>
                <div class="me-5">
                    <p class="mb-0 text-uppercase fs-6 text-muted fw-bold">{{ 'overview.cards.published'.trans() }}</p>
                    <p class="text-dark fs-4">{{ overviewData.publishedCatalogCards }}</p>
                </div>
                <div class="">
                    <p class="mb-0 text-uppercase fs-6 text-muted fw-bold">{{ 'overview.cards.draft'.trans() }}</p>
                    <p class="text-dark fs-4">{{ overviewData.draftCatalogCards }}</p>
                </div>
            </div>
            <!-- <div :class="{ 'd-flex px-260 mb-4': showOverviewData }" v-show="showOverviewData">
                <div class="select-download-container position-relative">
                    <select ref="downloadSelect">
                        <option value="">{{ 'select.download.placeholder'.trans() }}</option>
                        <option value="articlesFinalNoPrice">{{ 'select.download.articles_final_no_price'.trans() }}</option>
                        <option value="articlesFinal">{{ 'select.download.articles_final'.trans() }}</option>
                        <option value="tv">{{ 'select.download.tv'.trans() }}</option>
                        <option value="pagesAndCards">{{ 'select.download.pages_and_cards'.trans() }}</option>
                        <option value="articlesWeb">{{ 'select.download.articles_web'.trans() }}</option>
                    </select>
                    <i class="mdi mdi-triangle fs-7 text-dark position-absolute pointer-events-none"></i>
                </div>
                <button class="btn btn-dark select-download-button ms-2 fw-bold fs-5 text-uppercase"
                    :disabled="downloadDisabled"
                    @click="downloadData"
                >
                    {{ 'select.download.button'.trans() }}
                </button>
            </div> -->

            <PagesCarousel
                v-if="doneLoading"
                :carouselItems="sliderData"
                :isOverview="showOverviewData"
                @select="carouselActiveItemChange"
            />

            <div id="pages-tab" class="tab-pane mx-2 active">
                <div class="pages-tab-container d-flex">
                    <div class="main-container w-72">
                        <div class="d-flex align-items-center">
                            <button
                                v-if="status != 'finished'"
                                class="btn btn-outline d-flex align-items-center text-black fs-5 p-0 mb-3"
                                data-bs-toggle="modal"
                                data-bs-target="#reorderPagesModal"
                            >
                                <i
                                    class="mdi mdi-reorder-horizontal me-1 fs-3 text-dark"
                                ></i>
                                <span class="text-decoration-underline">
                                    {{ "catalog.pages.order".trans() }}
                                </span>
                            </button>
                            <div v-if="status != 'finished'"
                                class="select-sort-container position-relative d-flex align-items-center ms-4"
                            >
                                <p class="text-dark fs-5 me-2">{{ 'cards.sort_by'.trans() }}</p>
                                <select ref="sortSelect">
                                    <option value="projection">{{ 'card.sort.by_projection'.trans() }}</option>
                                    <option value="position">{{ 'card.sort.by_position'.trans() }}</option>
                                </select>
                                <i class="mdi mdi-triangle fs-7 text-dark position-absolute pointer-events-none"></i>
                            </div>
                        </div>
                        <div class="catalog-pages-container d-flex">
                            <Page
                                v-for="(page, index) in activePagesData"
                                :pageData="page"
                                :catalogId="id"
                                :activePagesIds="activePagesIds"
                                :catalogStatus="status"
                                :isLast="isLastPage"
                                :isRight="index == 1"
                                :sortCardsBy="sortSelectValue"
                                @pageAddClick="triggerAddModal"
                                @pageDeleteClick="triggerDeleteModal"
                                @productsShow="showProducts"
                                :key="page.id"
                            />
                        </div>
                    </div>

                    <BacklogContainer
                        :class="{ 'pointer-events-none': status == 'finished' }"
                        :catalogId="id"
                        :catalogStatus="status"
                        :activePagesIds="activePagesIds"
                        :isFinished="status == 'finished'"
                    />
                </div>
            </div>
            <div id="overview-tab" class="tab-pane mx-2">
                <div class="overview-tab-container d-flex">
                    <div class="main-container w-100">
                        <div class="catalog-pages-container d-flex">
                            <OverviewPage
                                v-for="(page, index) in activeOverviewPagesData"
                                :pageData="page"
                                :catalogId="id"
                                :catalogStatus="status"
                                :isRight="index == 1"
                                :activePagesIds="activeOverviewPagesIds"
                                @cardTVselected="selectTVcard"
                                :key="page.id"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <LockedCardModal />

        <TVmodal />

        <AddPageModal :pageData="lastClickedPageData" :catalogId="id" />

        <DeletePageModal :pageData="lastClickedPageData" :catalogId="id" />

        <ReorderPagesModal :pagesOrder="pages" :catalogId="id" />

        <DeleteCardModal :catalogId="id" />
        <SplitCardModal :backlogCards="allCards" :cardSingle="cardSingle" :cardSingleDraftData="cardSingleDraftData" @reloadPages="() => activePagesStore.fetchData(activePagesIds)" />
        <MergeCardModal :backlogCards="allCards" :originCard="cardSingle" @reloadPages="() => activePagesStore.fetchData(activePagesIds)" />
        <CreateVirtualModal />
        <SaveAndDeleteModal @saveAndDelete="triggerSaveAndPublish(true)"/>

        <DrawerBoxEditWrapper
            :catalogId="id"
            ref="drawerBoxEditWrapperComponent"
            :catalogIsFinished="status == 'finished'"
            @reloadPages="() => activePagesStore.fetchData(activePagesIds)"
        />
        <DrawerBoxOverviewWrapper
            :catalogIsFinished="status == 'finished'"
            @reloadOverviewPages="() => activePagesStore.fetchOverviewData(activeOverviewPagesIds)"
        />

        <CardProductsModal :cardTitle="showProductsCardTitle" />

        <DeleteCommentModal />

        <HeaderSearchDrawer :catalogIsFinished="status == 'finished'" :catalogId="id" />
    </div>
    <div
        v-else-if="activePagesDoneLoading && status != 'finished'"
        class="text-center d-flex align-items-center"
    >
        <p class="text-black fs-5 mb-0 ms-auto me-1">
            {{ "catalog.page.empty".trans() }}
        </p>
        <button
            class="btn btn-outline p-0 me-auto text-black fs-5 text-decoration-underline"
            data-bs-toggle="modal"
            data-bs-target="#catalogUpdateModal"
        >
            {{ "catalog.page.edit".trans() }}
        </button>
    </div>
</template>

<script setup>
    import { computed, onMounted, ref, watch } from "vue";
    import { usePageListStore } from "../js/frontend/stores/pageList";
    import { useActivePagesStore } from "../js/frontend/stores/activePages";
    import { useBacklogCardsStore } from "../js/frontend/stores/backlogCards";
    import { useCardSingleStore } from "../js/frontend/stores/cardSingle";
    import { useOverviewStore } from "../js/frontend/stores/overview";
    import { useCommentsStore } from "../js/frontend/stores/comments";
    import { useGeneralStore } from "../js/frontend/stores/general";
    import { updateStats } from "../js/frontend/api/page";
    import { download } from "../js/frontend/api/catalog";

    import PagesCarousel from "./components/PagesCarousel";
    import BacklogContainer from "./components/BacklogCards/BacklogContainer";
    import Page from "./components/Page";
    import OverviewPage from "./components/OverviewPage";
    import DrawerBoxEditWrapper from "./components/BoxEdit/DrawerBoxEditWrapper";
    import DrawerBoxOverviewWrapper from "./components/BoxOverview/DrawerBoxOverviewWrapper"
    import LockedCardModal from "./modals/LockedCardModal";
    import TVmodal from "./modals/TVmodal";
    import AddPageModal from "./modals/AddPageModal";
    import DeletePageModal from "./modals/DeletePageModal";
    import ReorderPagesModal from "./modals/ReorderPagesModal";
    import CardProductsModal from "./modals/CardProductsModal";
    import DeleteCardModal from "./modals/DeleteCardModal.vue";
    import SplitCardModal from "./modals/SplitCardModal";
    import MergeCardModal from "./modals/MergeCardModal";
    import CreateVirtualModal from "./modals/CreateVirtualModal";
    import DeleteCommentModal from './modals/DeleteCommentModal'
    import SaveAndDeleteModal from './modals/SaveAndDeleteModal'
    import HeaderSearchDrawer from './components/HeaderSearchDrawer'

    const props = defineProps({
        id: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            required: true,
        }
    });

    const pageListStore = usePageListStore();
    pageListStore.fetchData(props.id);

    const activePagesStore = useActivePagesStore();
    const backlogCardsStore = useBacklogCardsStore();
    const cardSingleStore = useCardSingleStore();
    const commentsStore = useCommentsStore();
    const generalStore = useGeneralStore();

    const overviewStore = useOverviewStore()
    overviewStore.fetchCatalogOverviewData(props.id)

    const backlogCards = ref(backlogCardsStore.getData);
    const allCards = ref(backlogCardsStore.getAllCards)
    const cardSingle = ref(cardSingleStore.getCurrentData);
    const cardSingleDraftData = ref(cardSingleStore.getDraftData);

    const showFullPageLoader = ref(generalStore.showFullPageLoader);

    const pages = ref(pageListStore.getData);
    const sliderData = ref(pageListStore.getPairs);
    const doneLoading = ref(!pageListStore.loading);

    const overviewData = ref(overviewStore.getOverviewData)

    const activePagesData = ref(activePagesStore.getData);
    const activeOverviewPagesData = ref(activePagesStore.getOverviewData);

    const activePagesDoneLoading = ref(!activePagesStore.isLoading);
    const activeOverviewPagesDoneLoading = ref(!activePagesStore.isOverviewLoading);

    const lastClickedPageData = ref({});
    const showProductsCardTitle = ref(null);
    const navTabs = ref(null)
    const showOverviewData = ref(false)
    const lastClickedPagesInSlider = ref([])

    const sortSelect = ref(null)
    const sortSelectValue = ref('projection')

    const downloadSelect = ref(null)
    const downloadDisabled = ref(true)

    const drawerBoxEditWrapperComponent = ref(null)

    const activePagesIds = computed(() =>
        activePagesData.value.map((page) => page.id)
    );
    const activeOverviewPagesIds = computed(() =>
        activeOverviewPagesData.value.map((page) => page.id)
    );
    const hasPages = computed(() => pages.value.length > 0);
    const isLastPage = computed(() => pages.value.length == 1);

    const hasSelectedTVcards = computed(() => overviewStore.getSelectedCards.value.length > 0)

    async function carouselActiveItemChange(emitData) {
        const isOverview = $(navTabs.value).find('.active').data('opens') == 'overviewTab'

        lastClickedPagesInSlider.value = emitData

        if (isOverview) await activePagesStore.fetchOverviewData(emitData);
        else await activePagesStore.fetchData(emitData);

        pageListStore.fetchData(props.id)
    }

    function showProducts(cardTitle) {
        showProductsCardTitle.value = cardTitle;
    }

    function triggerAddModal(emitData) {
        lastClickedPageData.value = emitData;
    }

    function triggerDeleteModal(emitData) {
        lastClickedPageData.value = emitData;
    }

    function goToPagesTab() {
        showOverviewData.value = false

        let fetchPagesIds = []

        if (lastClickedPagesInSlider.value) fetchPagesIds = lastClickedPagesInSlider.value
        else fetchPagesIds = activePagesIds.value

        activePagesStore.fetchData(fetchPagesIds);
    }

    function goToOverviewTab() {
        showOverviewData.value = true

        let fetchPagesIds = []

        if (lastClickedPagesInSlider.value) fetchPagesIds = lastClickedPagesInSlider.value
        else fetchPagesIds = activeOverviewPagesIds.value

        activePagesStore.fetchOverviewData(fetchPagesIds);

        commentsStore.fetchActivePagesComments(fetchPagesIds);

        initDownloadSelect()
    }

    function initDownloadSelect() {
        if ($(downloadSelect.value)[0].selectize) return

        $(downloadSelect.value).selectize({
            onChange: function(val) {
                if (val) {
                    downloadDisabled.value = false

                    if (val == 'tv' && !hasSelectedTVcards.value) {
                        downloadDisabled.value = true
                    }

                } else {
                    downloadDisabled.value = true
                }
            }
        })
    }

    function selectTVcard() {
        const val = $(downloadSelect.value).val()

        if (val && hasSelectedTVcards.value) downloadDisabled.value = false
        else downloadDisabled.value = true
    }

    async function downloadData() {
        const downloadType = $(downloadSelect.value).val()

        await download(downloadType, props.id, overviewStore.getSelectedCards.value)
    }

    function triggerSaveAndPublish(isDeleteOnly = false) {
        drawerBoxEditWrapperComponent.value.triggerSaveAndPublishData(isDeleteOnly)
    }

    // onMounted was too fast for some reason
    watch(sortSelect, (newVal, oldVal) => {
        if (newVal && !oldVal) {
            $(sortSelect.value).selectize({
                maxItems: 1,
                onChange: function(val) {
                    sortSelectValue.value = val
                }
            })
        }
    })
</script>

<style lang="sass" scoped>
    .spinner-wrapper-fullpage
        width: 100vw
        height: 100vh
        background-color: rgba(255, 255, 255, 0.8)
        position: fixed
        top: 0
        left: 0
        z-index: 99999

        .spinner-border 
            margin-top: calc(50vh - 1rem) !important

    .mdi-reorder-horizontal
        margin-bottom: -2px

    .pointer-events-none
        pointer-events: none !important

    .select-sort-container
        margin-bottom: 18px

        p
            margin-bottom: 7px

        .mdi-triangle
            top: 9px
            right: 12px
            z-index: 1
            transform: rotate(180deg)

    .select-download-container
        .mdi-triangle
            top: 16px
            right: 20px
            z-index: 1
            transform: rotate(180deg)

    .select-download-button
        height: 50px
</style>
