import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useGeneralStore = defineStore('general', () => {
    const fullPageLoading = ref(false)
    const showFullPageLoader = computed(() => fullPageLoading)

    function setisFullPageLoading(showLoader) {
        fullPageLoading.value = showLoader
    }

    return {
        setisFullPageLoading,
        showFullPageLoader
    }
})